import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const ProcessSingle = ({ img, link, title, desc }) => (
  <div class="single-offer d-flex flex-row pb-30-top">
    <div class="icon">
      <Img fixed={img} alt={title} />
    </div>
    <div class="desc">
      <Link to={link}>
        <h4 style={{ color: "#34495C" }}>{title}</h4>
      </Link>
      <div style={{ color: "black" }}>{desc}</div>
    </div>
  </div>
)

export default ProcessSingle

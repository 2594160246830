import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import ProcessSingle from "./processSingle"
import "../static/css/process.css"

const Process = () => {
  const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "1.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      two: file(relativePath: { eq: "2.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      three: file(relativePath: { eq: "3.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      four: file(relativePath: { eq: "4.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  console.log(data)
  return (
    <section class="container card mg-30">
      <div class="row ">
        <div class="col-md-12 text-align-center">
          <h2>How it works!</h2>
          <h3>Simple and transparent.</h3>
        </div>
      </div>
      <div class="row ">
        <div class="col-lg-6">
          <ProcessSingle
            img={data.one.childImageSharp.fixed}
            link="/chute-cleaning"
            title="Step 1: Book"
            desc="Call, email or use our online form to book a free inspection."
          />
          <ProcessSingle
            img={data.two.childImageSharp.fixed}
            link="/chute-cleaning"
            title="Step 2: Free Inspection"
            desc="We inspect the location for free and quote the needed services."
          />
        </div>
        <div class="col-lg-6">
          <ProcessSingle
            img={data.three.childImageSharp.fixed}
            link="/chute-cleaning"
            title="Step 3: Service"
            desc="Our trusted &amp; experienced cleaning professionals perform the
              needed services."
          />
          <ProcessSingle
            img={data.four.childImageSharp.fixed}
            link="/chute-cleaning"
            title="Step 4: Review"
            desc="We send you a service report and request feedback. Whether you’re
              thrilled or notice areas to improve, we'd love to hear about it."
          />
        </div>
      </div>
    </section>
  )
}

export default Process
